import { Heading } from '@chakra-ui/react';
import { FontSizes } from 'chakra-ui/fsmTheme';

const Header3 = ({ children, ...rest }) => {
  return (
    <Heading
      as="h3"
      fontSize={[FontSizes.header3.mobile, FontSizes.header3.mobile, FontSizes.header3.desktop]}
      letterSpacing="-.4px"
      lineHeight="110%"
      {...rest}
    >
      {children}
    </Heading>
  );
};

export default Header3;
