import { Box, Text } from '@chakra-ui/react';
import Link from 'next/link';

const NavLink = ({ href, text }) => (
  <Box whiteSpace="nowrap">
    <Link href={href}>
      <Text
        fontSize={18}
        fontWeight={700}
        letterSpacing=".5px"
        transition="200ms"
        _hover={{ color: 'gray.500' }}
        cursor="pointer"
      >
        {text}
      </Text>
    </Link>
  </Box>
);

export default NavLink;
