import { Box } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import CallUsButton from '@components/ra/common/CallUsButton';
import Button from '@components/ra/interactive/Button';
import { Routes, AepRoutes } from '@constants/routes';
import { useAuthenticatedContextProvider } from '@contexts/AuthenticatedContextProvider';
import NavLink from './NavLink';
import isAEP from '@utils/isAEP';

const DesktopNav = () => {
  const { authenticatedUser: user, isAgent, localUser } = useAuthenticatedContextProvider();
  const router = useRouter();

  const isAepRoute = AepRoutes.includes(router.pathname as Routes);

  let headerLinks = (
    <>
      <NavLink href={Routes.about} text="About" />
      <NavLink href={Routes.articles} text="Medicare 101" />
      <NavLink href={Routes.login} text="Current Clients" />
      <CallUsButton w={['100%', '100%', '100%', '100%']} />
    </>
  );

  if (isAgent) {
    headerLinks = (
      <>
        <NavLink href={Routes.about} text="About" />
        <NavLink href={Routes.articles} text="Medicare 101" />
        <NavLink href={Routes.agents} text="Agent Home" />
      </>
    );
  } else if (user && localUser) {
    headerLinks = (
      <>
        <NavLink href={Routes.dashboard} text="Home" />
        {localUser.multipleUsers?.length > 1 ? <NavLink href={Routes.chooseAccount} text="Switch Accounts" /> : null}
        <Button
          href={isAepRoute || isAEP() ? `${Routes.accountCallUs}?context=aep` : Routes.accountCallUs}
          fontSize={18}
        >
          {isAEP() ? 'Talk to an Agent' : 'Talk to Your Agent'}
        </Button>
      </>
    );
  }

  return (
    <Box gap="30px" alignItems="center" display={['none', 'none', 'none', 'flex']} flexShrink={0} minH="52px">
      {headerLinks}
    </Box>
  );
};

export default DesktopNav;
