/**
 * Returns boolean if we are currently in AEP.
 * AEP runs annually from October 15 - December 7.
 * Can be overridden by environment variable IS_AEP.
 * @returns boolean: if in AEP or not.
 */
export default function isAEP(): boolean {
  if (process.env.IS_AEP !== undefined) {
    return process.env.IS_AEP === 'true';
  }

  const today = new Date();
  const year = today.getUTCFullYear();
  const aepStart = new Date(`${year}-10-15T00:00:00Z`);
  const aepEnd = new Date(`${year}-12-07T23:59:59Z`);
  return today > aepStart && today < aepEnd;
}
