import { Stack } from '@chakra-ui/react';
import { Routes } from '@constants/routes';
import Card from './Card';

const CardLinks = () => {
  return (
    <Stack w={['100%', '100%', '100%', '50%']} spacing={0}>
      <Card href={Routes.about} text="About" bg="teal" />
      <Card href={Routes.articles} text="Medicare 101" bg="tan" />
      <Card href={Routes.login} text="Current Clients" bg="mint" />
      <Card href={Routes.bestMedicareSupplement} text="Medicare Supplement Plans" bg="highlight" />
      <Card href={Routes.topRatedMedicareAdvantage} text="Medicare Advantage Plans" bg="red.400" />
    </Stack>
  );
};

export default CardLinks;
