/* eslint-disable import/prefer-default-export */
import axios from 'axios';

export const askMedicareQuestion = async ({ email, meta, question }) => {
  try {
    return await axios.post(`${process.env.GRAPHQL_SERVER_ENDPOINT}/responses/ask`, {
      email,
      meta,
      question,
    });
  } catch (err) {
    return err;
  }
};

export const saveNewsletterEmail = async ({ email, meta }) => {
  try {
    return await axios.post(`${process.env.GRAPHQL_SERVER_ENDPOINT}/responses/newsletter`, {
      email,
      meta,
    });
  } catch (err) {
    return err;
  }
};
