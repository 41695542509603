import { Link } from '@chakra-ui/react';
import NextLink from 'next/link';

const NavButton = ({ href, text, ...rest }) => (
  <Link as={NextLink} href={href} variant="link" borderRadius={6} fontWeight="600" p={2} {...rest}>
    {text}
  </Link>
);

export default NavButton;
