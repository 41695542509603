import { getCallUsData } from '@constants/phoneNumbers';
import { useSem } from '@contexts/SemContextProvider';
import { useRouter } from 'next/router';
import Button from '../interactive/Button';

const CallUsButton = ({ href = null, text = null, ...rest }) => {
  const router = useRouter();

  const { dynamicPhoneNumber } = useSem();

  const dynamicProps = getCallUsData(dynamicPhoneNumber);

  const hrefWithQuery = {
    pathname: href || dynamicProps.href,
    query: router.query,
  };

  return (
    <Button
      id="call-us-button"
      className="call-us-button"
      fontSize={18}
      href={hrefWithQuery}
      letterSpacing=".5px"
      width="100%"
      {...rest}
    >
      {text || dynamicProps.text}
    </Button>
  );
};

export default CallUsButton;
