import { Avatar, Flex, Tooltip, useDisclosure } from '@chakra-ui/react';
import { fsmTeam } from '@constants/fsmTeam';
import Button from '@components/crm/lead/shared/Button';
import { useAuthenticatedContextProvider } from '@contexts/AuthenticatedContextProvider';
import { Mode, ModeToColor, useMode } from '@contexts/ModeContextProvider';
import { useVirgil } from '@contexts/VirgilContext';
import { faRobot, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { AgencyState } from '@components/crm/types';
import NavLink from '@components/ra/navigation/NavigationBar/DesktopNavigation/NavLink';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Routes } from '@constants/routes';
import BedrockContactUs from './BedrockContactUs';
import IconButton from '../../../crm/lead/shared/IconButton';
import BedrockLinks from './BedrockLinks';
import FsmLinks from './FsmLinks';

export enum AgencyName {
  FAIR_SQUARE = 'FAIR_SQUARE',
  BEDROCK = 'BEDROCK',
}

export default function BedrockNavbar() {
  // Resources
  const { localUser, authenticatedUser: agent, isBedrock, isAgent, handleLogout } = useAuthenticatedContextProvider();
  const { setIsVirgilOpen } = useVirgil();
  const mode = useMode();

  const { isOpen, onOpen, onClose } = useDisclosure();

  // Get the agent info for the logged-in agent using the calendly slug.
  const agentInfo =
    agent &&
    agent?.agent_profile?.scheduling_system_slug &&
    fsmTeam.find((member) => member.calendly === agent?.agent_profile?.scheduling_system_slug);
  const agentName = agentInfo
    ? agentInfo?.name
    : agent && agent.first_name
    ? `${agent.first_name} ${agent.last_name}`
    : '';

  // Flags
  const showSwitchUser = !isAgent && localUser?.multipleUsers?.length > 1;
  const trialAgency = agent?.agency?.state === AgencyState.TRIAL;

  return (
    <Flex
      borderBottom="1px solid"
      borderBottomWidth={mode === Mode.DEFAULT ? 1 : 3}
      borderColor={mode === Mode.DEFAULT ? 'gray.300' : ModeToColor[mode]}
      fontWeight={600}
      py={2}
      px={10}
      justifyContent="space-between"
      userSelect="none"
      bgColor="white"
    >
      <Flex alignItems="center">{isBedrock ? <BedrockLinks /> : <FsmLinks />}</Flex>
      <Flex alignItems="center" gap={3}>
        {isAgent && (
          <Tooltip label={agentName}>
            <Avatar
              size="sm"
              bg="gray.400"
              name={agentName}
              src={agentInfo && agentInfo.image.src}
              fontWeight={600}
              color="white"
            />
          </Tooltip>
        )}
        {(mode === Mode.DEMO || !isBedrock) && (
          <IconButton
            variant="solid"
            colorScheme="primary"
            icon={faRobot}
            label="Ask Virgil"
            onClick={() => setIsVirgilOpen(true)}
            size="sm"
          />
        )}
        <Flex gap={4} alignItems="center">
          {showSwitchUser && <NavLink href={Routes.chooseAccount} text="Switch Accounts" />}
          {isAgent && <IconButton label="Sign out" onClick={handleLogout} icon={faSignOut} size="sm" />}
        </Flex>
      </Flex>
    </Flex>
  );
}
