import { Box, Flex, Stack } from '@chakra-ui/react';
import { saveNewsletterEmail } from '@components/omniBox/requests';
import EmailInput from '@components/ra/inputs/EmailInput';
import TextLink from '@components/ra/interactive/TextLink';
import Body1 from '@components/ra/typography/Body1';
import Body2 from '@components/ra/typography/Body2';
import { getPhoneLink, hyphenatePhoneNumber, PhoneNumbers } from '@constants/phoneNumbers';
import { antlerStyle } from '@constants/styles';
import { useSem } from '@contexts/SemContextProvider';
import emailIcon from '@public/ra/icons/tertiary/email.svg';
import phoneIcon from '@public/ra/icons/tertiary/phone.svg';
import Image from 'next/image';

const NewsletterForm = () => {
  const { dynamicPhoneNumber } = useSem();

  const path = typeof window === 'undefined' ? '' : window!.location.href;
  const localUser = typeof window === 'undefined' ? null : window!.localStorage.getItem('user');

  const handleSubmit = async (email: string) => {
    await saveNewsletterEmail({ email, meta: { path, localUser } });
  };

  const phoneHref = getPhoneLink(dynamicPhoneNumber ?? PhoneNumbers.LANDING);
  const phoneText = hyphenatePhoneNumber(dynamicPhoneNumber ?? PhoneNumbers.LANDING);

  return (
    <Stack w={['100%', '100%', '100%', '50%']} flex="1 1 450px" spacing={6} px={antlerStyle.px} py="72px">
      <Body1 bold>Get the Fair Square Bulletin</Body1>
      <Body2>Medicare savings tips, helpful guides, and more.</Body2>
      <Box maxW="450px">
        <EmailInput buttonText="Sign Up" onSubmit={handleSubmit} toastText="Signed up for FSM Newsletter!" />
      </Box>
      <Flex gap={[3, 3, 8]} flexDir={['column', 'column', 'column', 'row']}>
        <Flex gap={2} alignItems="center" whiteSpace="nowrap">
          <TextLink className="phone-link" href={phoneHref} text={phoneText} />
          <Image src={phoneIcon} alt="Phone Icon" />
        </Flex>
        <Flex gap={2} alignItems="center" whiteSpace="nowrap">
          <TextLink href="mailto:info@fairsquaremedicare.com" text="info@fairsquaremedicare.com" />
          <Image src={emailIcon} alt="Email Icon" />
        </Flex>
      </Flex>
    </Stack>
  );
};

export default NewsletterForm;
