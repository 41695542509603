import { Flex } from '@chakra-ui/react';
import { useAuthenticatedContextProvider } from '@contexts/AuthenticatedContextProvider';
import BedrockLayout from './bedrock/BedrockLayout';
import FsmLayout from './fsm/FsmLayout';

const DefaultLayout = ({ children, showFooter = true }) => {
  // Resources
  const { isBedrock, isAgent } = useAuthenticatedContextProvider();
  const useBedrockLayout = isBedrock || isAgent;

  return (
    <Flex flexDir="column" overflow="visible" minH="100vh" justifyContent="flex-start" bg="darkBackground">
      {useBedrockLayout ? (
        <BedrockLayout>{children}</BedrockLayout>
      ) : (
        <FsmLayout showFooter={showFooter}>{children}</FsmLayout>
      )}
    </Flex>
  );
};

export default DefaultLayout;
