import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';
import Header3 from '@components/ra/typography/Header3';
import { defaultTransition } from '@constants/styles';
import Link from 'next/link';

const Card = ({ href, text, bg }) => {
  return (
    <Link href={href}>
      <Flex
        py={['30px', '30px', '40px']}
        px={[10, 10, 20, '80px']}
        bg={bg}
        alignItems="center"
        gap={4}
        transition={defaultTransition}
        _hover={{ gap: 8 }}
      >
        <Header3>{text}</Header3>
        <ArrowForwardIcon width={8} height={8} />
      </Flex>
    </Link>
  );
};

export default Card;
