import { Box, Flex } from '@chakra-ui/react';
import { Routes } from '@constants/routes';
import { navHeight } from '@constants/styles';
import { useAuthenticatedContextProvider } from '@contexts/AuthenticatedContextProvider';
import { ModeToColor, useMode } from '@contexts/ModeContextProvider';
import wordmark from '@public/ra/logo/wordmarks/fsm-wordmark-black.svg';
import Image from 'next/image';
import Link from 'next/link';
import { Banner } from './Banner';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';

const NavigationBar = () => {
  const { localUser } = useAuthenticatedContextProvider();
  const mode = useMode();
  const showBanner = true && !localUser;
  const offset = showBanner ? navHeight - 10 + 50 : navHeight - 10;

  return (
    <>
      <Box zIndex={9} bg="white" position="fixed" w="100vw" top={0}>
        {showBanner && <Banner />}
        <Box
          borderBottom="10px solid"
          borderColor={ModeToColor[mode]}
          display="flex"
          justifyContent="space-between"
          px={[4, 8]}
          py={4}
          alignItems="center"
          gap={8}
        >
          <Flex w="100%" maxW="1400px" justifyContent="space-between" mx="auto" alignItems="center">
            <Link href={Routes.index}>
              <Box display="flex" width={[136, 230]} height={['25px', '40px']} position="relative" flexShrink={1}>
                <Image src={wordmark} alt="Fair Square Medicare Wordmark" fill />
              </Box>
            </Link>
            <MobileNavigation />
            <DesktopNavigation />
          </Flex>
        </Box>
      </Box>
      {/* Offset Content */}
      <Box height={`${offset}px`} />
    </>
  );
};

export default NavigationBar;
