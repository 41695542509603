import { ChevronDownIcon } from '@chakra-ui/icons';
import NextLink from 'next/link';
import { Button, Link, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { Routes } from '@constants/routes';
import { Mode, useMode } from '@contexts/ModeContextProvider';
import fsmLogo from '@public/ra/logo/symbols/fsm-symbol-dynamic-bg-50.svg';
import Image from 'next/image';
import NavButton from './NavButton';

export enum AgencyName {
  FAIR_SQUARE = 'FAIR_SQUARE',
  BEDROCK = 'BEDROCK',
}

export default function FsmLinks() {
  const mode = useMode();

  const isCypress = 'Cypress' in window;

  return (
    <>
      <NextLink href={Routes.agents} style={{ marginRight: '16px' }}>
        <Image alt="FSM Logo" src={fsmLogo} height={30} />
      </NextLink>
      <NavButton href={Routes.agentsPipeline} isExternal={mode !== Mode.DEV && !isCypress} text="Pipeline" />
      <NavButton href={Routes.aircall} isExternal={mode !== Mode.DEV} text="Aircall" />
      <NavButton href={Routes.submitCerts} isExternal text="Submit 2025 Certs" />
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} colorScheme="white" color="black" size="sm" p={2}>
          Bug and Feature Requests
        </MenuButton>
        <MenuList>
          <Link as={NextLink} _hover={{ textDecoration: 'none' }} href={Routes.featureRequest} target="_blank">
            <MenuItem>Submit a Feature Request</MenuItem>
          </Link>
          <Link as={NextLink} _hover={{ textDecoration: 'none' }} href={Routes.bugReport} target="_blank">
            <MenuItem>Submit a Bug </MenuItem>
          </Link>
          <Link as={NextLink} _hover={{ textDecoration: 'none' }} href={Routes.linearOverview} target="_blank">
            <MenuItem>See Bugs/Feature Requests</MenuItem>
          </Link>
        </MenuList>
      </Menu>
    </>
  );
}
