import { Flex } from '@chakra-ui/react';
import BedrockNavbar from '@components/common/Navbar/BedrockNavbar/BedrockNavbar';
import VirgilProvider from '@contexts/VirgilContext';

const BedrockLayout = ({ children }) => {
  return (
    <VirgilProvider>
      <Flex flexDir="column" overflow="hidden" minH="100vh" justifyContent="flex-start" bg="white">
        <BedrockNavbar />
        {children}
      </Flex>
    </VirgilProvider>
  );
};

export default BedrockLayout;
