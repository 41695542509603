import { Flex, Stack, Text } from '@chakra-ui/react';
import Body2 from '@components/ra/typography/Body2';
import Link from '@components/ra/typography/Link';
import { Routes } from '@constants/routes';
import { antlerStyle } from '@constants/styles';
import CardLinks from './CardLinks';
import NewsletterForm from './NewsletterForm';

const Footer = () => {
  const currentYear = new Date().getFullYear().toString();

  return (
    <Stack spacing={0} borderTop="10px solid" borderTopColor="teal" borderBottom="10px solid" borderBottomColor="blue">
      <Flex
        bg="white"
        alignItems="center"
        flexDir={['column', 'column', 'column', 'row']}
        borderBottom="10px solid"
        borderBottomColor="red.400"
      >
        <NewsletterForm />
        <CardLinks />
      </Flex>
      <Stack spacing={10} bg="tan" py={20} px={antlerStyle.px}>
        <Flex gap={4}>
          <Flex gap={2}>
            <Body2 display="inline">{`Fair Square ${currentYear}`}</Body2>
            &#x2022;{' '}
            <Link href={Routes.termsOfService} fontWeight={500}>
              Terms of Use
            </Link>
            &#x2022;{' '}
            <Link href={Routes.privacyPolicy} fontWeight={500}>
              Notice of Privacy Practices
            </Link>
          </Flex>
        </Flex>
        <Stack opacity={0.6} spacing={4}>
          <Text>
            {`Virgil Insurance Agency, LLC (DBA Fair Square Medicare) and www.fairsquaremedicare.com are privately owned
            and operated by Help Button Inc. Medicare supplement insurance plans are not connected with or endorsed by
            the U.S. government or the federal Medicare program. This is a solicitation of insurance. A licensed
            agent/producer may contact you. Medicare Supplement insurance is available to those age 65 and older
            enrolled in Medicare Parts A and B and, in some states, to those under age 65 eligible for Medicare due to
            disability or End-Stage Renal disease. Virgil Insurance Agency is a licensed and certified representative of
            Medicare Advantage HMO, HMO SNP, PPO, PPO SNP and PFFS organizations and stand-alone PDP prescription drug
            plans. Each of the organizations we represent has a Medicare contract. Enrollment in any plan depends on
            contract renewal. The plans we represent do not discriminate on the basis of race, color, national origin,
            age, disability, or sex. Plan availability varies by region and state. For a complete list of available
            plans please contact 1-800-MEDICARE (TTY users should call 1-877-486-2048), 24 hours a day/7 days a week or
            consult www.medicare.gov. © ${currentYear} Help Button Inc`}
          </Text>
          <Text>
            We do not offer every plan available in your area. Any information we provide is limited to those plans we
            do offer in your area. Please contact Medicare.gov or 1-800-MEDICARE to get information on all of your
            options.
          </Text>
          <Text>MULTIPLAN_FairSquareMedicare_01062022_M</Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;
