import { Box, Flex, FormControl, FormErrorMessage, Input, useToast } from '@chakra-ui/react';
import { textInputHeight } from '@constants/styles';
import checkmark from '@public/ra/icons/checkmark.svg';
import { Field, Form, Formik } from 'formik';
import Image from 'next/image';
import Button from '../interactive/Button';
import Body2 from '../typography/Body2';

const validateEmail = (email: string) => {
  const emailRegex = /\S+@\S+\.\S+/;
  if (!emailRegex.test(email) && email !== '') return "Are you sure that's your email?";
};

const EmailInput = ({ buttonText = 'Sign Up', onSubmit, width = '100%', toastText = 'Success!' }) => {
  const toast = useToast();

  const handleSubmit = async (values, { resetForm }) => {
    onSubmit(values.email);
    resetForm();
    toast({
      duration: 4000,
      position: 'bottom',
      render: () => (
        <Flex
          bg="highlight"
          py={4}
          px={8}
          mb={12}
          borderRadius={99}
          alignItems="center"
          gap={3}
          width="fit-content"
          mx="auto"
        >
          <Image src={checkmark} alt="Checkmark" />
          <Body2 bold>{toastText}</Body2>
        </Flex>
      ),
    });
  };

  return (
    <Box position="relative" width={width}>
      <Formik initialValues={{ email: '' }} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <Flex h={textInputHeight}>
              {/* Height prevents hint from moving content (60 + 17, 64 + 25) */}
              <Field name="email" type="email" validate={validateEmail}>
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.email && form.touched.email}>
                    <Input
                      id="email"
                      type="email"
                      width="100%"
                      bg="white"
                      color="black"
                      placeholder="Email Address"
                      {...field}
                      height={['60px', '60px', 16]}
                      fontSize={16}
                      pl={6}
                      pr={[132, 132, 154, 154]}
                      borderRadius={99}
                      border="none"
                      required
                      boxShadow="0px 4px 64px 0px #0000001A"
                      _focusWithin={{
                        boxShadow: '0px 4px 64px 0px #0000001A',
                      }}
                    />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Box position="absolute" right={['2px', '2px', '4px']} top={['2px', '2px', '4px']} zIndex={3}>
                <Button whiteSpace="nowrap" type="submit" isLoading={isSubmitting} px={[6, 6, 10, 10]}>
                  {buttonText}
                </Button>
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EmailInput;
