import { ArrowForwardIcon } from '@chakra-ui/icons';
import { HStack } from '@chakra-ui/react';
import Body2 from '@components/ra/typography/Body2';
import { FsmColors } from 'chakra-ui/fsmTheme';
import Link from 'next/link';

export function Banner() {
  const href = 'https://www.medicarevox.com';

  return (
    <Link href={href} target="_blank">
      <HStack
        style={{
          zIndex: 99,
          backgroundColor: FsmColors.highlight,
          padding: 13,
        }}
        display="flex"
        justifyContent="center"
      >
        <HStack gap={0}>
          <Body2 style={{ fontWeight: 600 }} textAlign="center">
            Introducing Vox, the AI future of Medicare: www.medicarevox.com
          </Body2>
          <ArrowForwardIcon boxSize={5} />
        </HStack>
      </HStack>
    </Link>
  );
}
