import { Flex, MenuList } from '@chakra-ui/react';
import TextLink from '@components/ra/interactive/TextLink';
import { Routes } from '@constants/routes';
import { useAuthenticatedContextProvider } from '@contexts/AuthenticatedContextProvider';

const MobileLinks = () => {
  const { authenticatedUser: user, isAgent, localUser } = useAuthenticatedContextProvider();

  let headerLinks = (
    <>
      <TextLink href={Routes.about} text="About" textDecoration="none" />
      <TextLink href={Routes.articles} text="Articles" textDecoration="none" />
      <TextLink href={Routes.login} text="Current Clients" textDecoration="none" />
    </>
  );

  if (user && isAgent) {
    headerLinks = (
      <>
        <TextLink href={Routes.about} text="About" textDecoration="none" />
        <TextLink href={Routes.articles} text="Articles" textDecoration="none" />
        <TextLink href={Routes.agents} text="Agent Home" textDecoration="none" />
      </>
    );
  } else if (user && localUser) {
    headerLinks = (
      <>
        <Flex pb={2} w="100%">
          {localUser.multipleUsers?.length > 1 ? <TextLink href={Routes.chooseAccount} text="Switch Accounts" /> : null}
        </Flex>
        <TextLink href={Routes.dashboard} text="Home" textDecoration="none" />
      </>
    );
  }

  return (
    <MenuList
      border="8px solid"
      borderColor="teal"
      borderRadius={0}
      display="flex"
      flexDir="column"
      gap={4}
      px={4}
      py={50}
      w="100vw"
    >
      {headerLinks}
    </MenuList>
  );
};

export default MobileLinks;
