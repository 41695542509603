import { Flex } from '@chakra-ui/react';
import FsmFooter from '@components/ra/navigation/Footer';
import NavigationBar from '@components/ra/navigation/NavigationBar';

const FsmLayout = ({ children, showFooter = true }) => {
  return (
    <Flex flexDir="column" minH="100vh" justifyContent="flex-start">
      <NavigationBar />
      {children}
      {showFooter ? <FsmFooter /> : null}
    </Flex>
  );
};

export default FsmLayout;
