import { HStack } from '@chakra-ui/react';
import { Routes } from '@constants/routes';
import { useAuthenticatedContextProvider } from '@contexts/AuthenticatedContextProvider';
import { Mode, useMode } from '@contexts/ModeContextProvider';
import bedrockLogo from '@public/crm/bedrock.svg';
import Image from 'next/image';
import Link from 'next/link';
import NavButton from './NavButton';

const BedrockLinks = () => {
  const mode = useMode();
  const { authenticatedUser: user, isAgent, isOpenbook } = useAuthenticatedContextProvider();
  let logoHref = Routes.index;
  if (isAgent) {
    logoHref = Routes.agents;
  } else if (user) {
    logoHref = Routes.dashboard;
  }

  const showPipeline = isAgent && !isOpenbook;
  const showOpenbook = isAgent && isOpenbook;

  return (
    <>
      <Link href={logoHref} style={{ marginRight: '20px' }}>
        <Image alt="Agency Logo" src={bedrockLogo} height={30} />
      </Link>
      <HStack gap={1}>
        {showPipeline && <NavButton href={Routes.agentsPipeline} isExternal={mode !== Mode.DEV} text="Pipeline" />}
        {showOpenbook && <NavButton href={Routes.agentsOpenbook} isExternal={mode !== Mode.DEV} text="Openbook" />}
      </HStack>
    </>
  );
};

export default BedrockLinks;
