const PhoneIcon = ({ fill, ...rest }) => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M16.0032 11.7141L13.9506 13.7906C13.7144 14.0294 13.3473 14.0802 13.0554 13.9145C10.7098 12.5449 8.75756 10.5929 7.38825 8.24737C7.2225 7.95537 7.27298 7.58798 7.51216 7.35216L9.58831 5.27601V5.27568C9.82283 5.04115 9.87332 4.67974 9.71221 4.39008L7.6978 0.804138C7.56426 0.569285 7.31545 0.423783 7.04539 0.423119H2.34501C1.93443 0.420794 1.54112 0.589209 1.25939 0.888182C0.977668 1.18715 0.832871 1.58942 0.859115 1.99931C1.2408 6.75649 3.30365 11.2227 6.67767 14.5979C10.052 17.9729 14.5175 20.0367 19.2745 20.4198C19.6851 20.4473 20.0887 20.3032 20.3887 20.0211C20.6887 19.7394 20.8578 19.3455 20.8555 18.9339V14.2335C20.8548 13.9635 20.7093 13.7147 20.4744 13.5811L16.8885 11.6142C16.6038 11.4472 16.2431 11.488 16.0029 11.7142L16.0032 11.7141Z"
        fill={fill}
      />
    </svg>
  );
};

export default PhoneIcon;
