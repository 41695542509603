import { Box, Menu, MenuButton } from '@chakra-ui/react';
import CallUsButton from '@components/ra/common/CallUsButton';
import PhoneIcon from '@components/ra/icons/tertiary/PhoneIcon';
import Button from '@components/ra/interactive/Button';
import { AepRoutes, Routes } from '@constants/routes';
import { useAuthenticatedContextProvider } from '@contexts/AuthenticatedContextProvider';
import burger from '@public/ra/icons/control/hamburger.svg';
import Image from 'next/image';
import { useRouter } from 'next/router';
import MobileLinks from './MobileLinks';
import isAep from '@utils/isAEP';

const MobileNavigation = () => {
  const { authenticatedUser: user, localUser } = useAuthenticatedContextProvider();
  const router = useRouter();

  const isAepRoute = AepRoutes.includes(router.pathname as Routes);

  return (
    <Box alignItems="center" display={['flex', 'flex', 'flex', 'none']}>
      {user && localUser ? (
        <>
          <Button
            href={isAepRoute || isAep() ? `${Routes.accountCallUs}?context=aep` : Routes.accountCallUs}
            fontSize={18}
          >
            <Box display={['none', 'none', 'none']}>Talk to Your Agent</Box>
            <PhoneIcon
              display={['block', 'block', 'none']}
              fill="white"
              border="2px solid #335eea"
              rounded="lg"
              w={35}
              h={35}
            />
          </Button>
        </>
      ) : (
        <CallUsButton w={['100%', '100%', '100%', '100%']} />
      )}
      <Menu>
        <MenuButton transition="all 0.2s">
          <Box ml={[4, 6, 7, 10]} minW="44px">
            <Image src={burger} height={44} width={44} alt="Hamburger Menu Icon" />
          </Box>
        </MenuButton>
        <MobileLinks />
      </Menu>
    </Box>
  );
};

export default MobileNavigation;
